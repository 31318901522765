<template>
  <carousel :items-to-show="slidesToShow" :wrap-around="true" :autoplay="3500" :transition="1000">
    <Slide v-for="slide in slides" :key="slide" :class="[bordered === true? 'border-1px-black':'',['mobile','tabletP','tabletL'].includes($store.state.screenSize)&&'h100']">
      <div class="carousel__item"><img :src="slide"></div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'custom-carousel',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props:['slides','noOfSlides','bordered'],
  computed:{
    slidesToShow(){
      if(this.noOfSlides === undefined){
        if(this.$store.state.screenSize === 'mobile')
          return 1.5
        else if(this.$store.state.screenSize === 'tablet')
          return 2.5
        else return 3.5
      }
      return this.noOfSlides
    }
  },
}
</script>
<style scoped lang="scss">
  .carousel__slide{
    height: 260px;
  }
  .carousel__item{
    justify-content: center;
    align-content: center;
  }
  .mobile .carousel__slide,
  .tablet .carousel__slide{
    height: 130px;
  }

  .mobile .carousel__item,
  .tablet .carousel__item{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 130px
  }
  img{
    max-height: 100px;
    max-width: 90%;
  }
</style>
