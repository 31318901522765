import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    redirect:'/hu',
    props: true,
    component: HomeView
  },
  {
    path: '/hu',
    name: 'fooldal',
    props: true,
    component: HomeView
  },
  {
    path: '/hu/flakongyartas',
    name: 'flakongyartas',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BottlesMainView.vue')
  },
  {
    path: '/hu/kanalgyartas',
    name: 'kanalgyartas',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BucketsMainView.vue')
  },
  {
    path: '/hu/flakonfuvo-gepgyartas',
    name: 'flakonfuvo_gepgyartas',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BlowingMachineMainView.vue')
  },
  {
    path: '/hu/szerelekek/:idOfProd',
    name: 'szerelekek',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductPage.vue')
  },

    //ENGLISH
  {
    path: '/en',
    name: 'home',
    props: true,
    component: HomeView
  },
  {
    path: '/en/bottle-production',
    name: 'bottleProduction',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BottlesMainView.vue')
  },
  {
    path: '/en/bucket-production',
    name: 'bucketProduction',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BucketsMainView.vue')
  },
  {
    path: '/en/blowing-machine-production',
    name: 'blowingMachine',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BlowingMachineMainView.vue')
  },
  {
    path: '/en/attachments/:idOfProd',
    name: 'attachments',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductPage.vue')
  },

    //ROMANIAN
  {
    path: '/ro',
    name: 'principală',
    props: true,
    component: HomeView
  },
  {
    path: '/ro/flacoane',
    name: 'flacoane',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BottlesMainView.vue')
  },
  {
    path: '/ro/cupe',
    name: 'cupe',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BucketsMainView.vue')
  },
  {
    path: '/ro/masini-de-suflat-flacoane',
    name: 'mașini-de-suflat-flacoane',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BlowingMachineMainView.vue')
  },
  {
    path: '/ro/accesorii/:idOfProd',
    name: 'accesorii',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductPage.vue')
  },

  //SPANISH
  {
    path: '/es',
    name: 'inicio',
    props: true,
    component: HomeView
  },
  {
    path: '/es/botellas',
    name: 'botellas',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BottlesMainView.vue')
  },
  {
    path: '/es/cucharas',
    name: 'cucharas',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BucketsMainView.vue')
  },
  {
    path: '/es/maquinas-de-soplado-de-botellas',
    name: 'maquinas-de-soplado-de-botellas',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/BlowingMachineMainView.vue')
  },
  {
    path: '/es/accesorios/:idOfProd',
    name: 'accesorios',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductPage.vue')
  },
]

const base = process.env.NODE_ENV === 'production' ? '/' : '/';


const router = createRouter({
  history: createWebHistory(base),
  routes
})

export default router
