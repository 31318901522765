<template>
  <div :class="[$store.state.screenSize, 'aboutUs','gap20',['desktop','laptop'].includes($store.state.screenSize)?'w1300 gridTemplateRowsAuto600':'',$store.state.screenSize==='tabletL'?'w800 gridTemplateRowsAutoAuto500':'']">
    <div id="leftSide">
      <text-with-line-after :text="gl('au1')"/>
      <h3 id="solutions" :class="[['desktop','laptop','tabletL'].includes($store.state.screenSize)?'leftText':'middleText']">{{gl('au2')}}</h3>
    </div>
    <div id="rightSide">
      <p id="companyInfo" :class="[['desktop','laptop','tabletL'].includes($store.state.screenSize)?'leftText':'middleText']">
        <b>{{gl('au3')}}</b><br><br>
        <span v-if="['desktop','laptop'].includes($store.state.screenSize)">{{gl('au4')}}</span>
      </p>
    </div>
    <span v-if="['tabletL',''].includes($store.state.screenSize)" class="grid-element-span2-column">{{gl('au4')}}</span>
    <div id="aboutUsBanner">
      <img src="aboutUs_banner.webp">
    </div>
  </div>
</template>
<script>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";

export default {
  name: "AboutUs",
  components: {TextWithLineAfter},
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
}
</script>

<style scoped lang="scss">
  .aboutUs{
    display: grid;
    margin: 0 auto;
    #our{
      position: relative;
      width: fit-content;
      height: 2vh;
      font-family: 'Inter', sans-serif;
      color: var(--orange-color);
      &::after{
        content: "";
        position: absolute;
        right: -40%;
        height: 1.5px;
        top: 35%;
        width: 50px;
        background-color: var(--orange-color);
      }
    }
    #solutions{
      font-family: Inter-ExtraBold,sans-serif;
      font-size: var(--titleFont, 60px);
      text-transform: uppercase;
      margin: unset;
    }
    #companyInfo{
      margin-top: 10%;
      font-size: var(--largeTextFont,23px);
      span{
        font-size: var(--textFont,20px)
      }
    }
    #aboutUsBanner{
      grid-column-start: 1;
      grid-column-end: 3;
      img{
        width: 100%;
      }
    }
    &.mobile,
    &.tablet{
      width: 100%;
      grid-template-rows: 1fr;
      gap: 30px;
      #leftSide,#rightSide,#aboutUsBanner{
        margin: 0 auto;
      }
      #leftSide{
        width: 85%;
      }

      #rightSide{
        width: 85%;
        #companyInfo{
          span{
            font-size: 19px;
          }
        }
      }
      #aboutUsBanner{
        grid-column: 1;
        width: 85%;
      }
    }
  }
</style>