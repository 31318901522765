<template>
  <button class="pulse-orange b-r-50px p15  no-border pointer" :class="black?'pulse-black bg-black w400':'pulse-orange bg-orange w300'" :style="this.$store.state.sharedStyle">
    <p class="InterExtraBold m0 whiteText" :class="fontSize === undefined? 'xLargeText':fontSize">
      {{text===undefined?gl('h9'):text }}
    </p>
  </button>
</template>
<script>

export default {
  name: "EnquiryButton",
  props:['fontSize', 'text','black'],
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
}
</script>