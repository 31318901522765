<template>
  <div class="branchCard">
    <img :src="image+'.webp'">
    <div class="fact" :class="$store.state.screenSize==='mobile'?'left-5p':'left-10p'" :style="CSSProp">
      <h1 :style="{color:title_color}" class="no-wrap">{{title}}</h1>
      <h2 :style="{color:subtitle_color}"> {{fact_title}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name:'branchCard',
  props:['title','url','fact_title','fact_text','title_color','subtitle_color','image', 'after_text'],
  computed:{
    CSSProp(){
      return{
        '--afterColor': this.title_color,
        '--afterContent': `"${this.after_text}"`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.branchCard {
  display: flex;
  position: relative;
  height: fit-content;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.05);
    }
    cursor: pointer;
  }
  img {
    width: 100%;
    transition: transform 1.2s ease;
  }

  .fact {
    position: absolute;
    bottom: 6%;
    text-align: left;

    h1 {
      font-family: 'Inter-ExtraBold', sans-serif;
      white-space: break-spaces;
      font-size: 2.9vh;
      margin-bottom: unset;
      color: black;
    }

    h2 {
      font-family: Inter-Medium, sans-serif;
      margin-top: 1%;
      color: var(--orange-color);
      white-space: pre-wrap;
      font-size: 1.7vh;
    }

    &::before {
      content: '';
      position: absolute;
      width: 22vw;
      height: 1px;
      top: 10%;
      background-color: black;
    }

    &::after {
      content: var(--afterContent, 'Olvass tovább →');
      font-weight: bold;
      font-size: 1.45vh;
      color: var(--afterColor, black);
      &:hover {
        transform: scale(2);
      }
    }
  }
}
</style>
