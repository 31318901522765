<script>
import Popper from "vue3-popper";
import {email_dispatcher} from "@/mixins/email_dispatcher";
export default {
  name: "EnquiryForm",
  components:{
    Popper
  },
  data(){
    return{
      nev: '',
      email: '',
      telefon: '',
      uzenet: '',
      bajok: [],
      mutasdVannakHibak: false,
      jelentkezett: false,
      cardOne: "start",
      nevPlaceHolder: "Név*"
    }
  },
  mixins: ['email_dispatcher'],
  methods:{
    closeModal(){
      this.$emit('close');
    },
    hibaKereses() {
      this.bajok = [];
      if (this.nev.length < 8) {
        this.bajok.push('Adja meg a nevet!')
      }
      if (this.email.length < 8) {
        this.bajok.push('Adja meg az email címet!')
      }
      else
      if( this.email.search('@') === -1)
        this.bajok.push('Az email cím formátuma nem megfelelő!')

      if (this.bajok.length === 0) {
        this.$refs.gomb.classList.add('mehet')
      } else {
        this.$refs.gomb.classList.remove('mehet')
      }
    },
    vannakEHibak() {
      this.hibaKereses()
      if (this.bajok.length > 0) {
        this.mutasdVannakHibak = true;
      }
    },
    tuntesdElVannakHibak() {
      setTimeout(() => {
        this.mutasdVannakHibak = false;
      }, 600)
    },
    emailKuldes() {
      if(this.bajok.length === 0) {   //CSAK AKKOR KULDJUNK EMAILT, HA MINDEN KI VAN TOLTVE
        //let nev = this.nev.split(' ').pop()
        email_dispatcher.methods.email_kuldes("Új érdeklődés került rögzítésre a Landing oldalunkon.<br><br><b>Érdeklődő adatai:</b><br>Név: " + this.nev + "<br>Email cím: " + this.email + "<br>Telefonszám: " + this.telefon + "<br>Üzenet: " + this.uzenet, "gyurasz.zoltan@poliol.hu", "ZOLI", "Új érdeklődő -- HONLAP")
      }
    },
  },
  beforeUpdate() {
    this.hibaKereses()
  },
  mounted(){
    this.hibaKereses()
  }
}
</script>

<template>
  <div class="modal">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-close" id="closeMobile" @click="closeModal"></div>
    <div id="urlap" class=" urlap flipCard__face modal-card" ref="urlap" v-bind:class="{ flipme: cardOne == 'flipped' }">
      <h2 class="title">Kérjen ajánlatot</h2>

      <div id="urlap_elemek">
            <div class="form-control">
              <input id="nev" v-model="nev" type="text" class="input" placeholder="Név*" required>
            </div>
            <div class="form-control">
              <input id="email" v-model="email" type="email" class="input is-large" placeholder="Email cím*" required>
            </div>
            <div class="form-control">
              <input id="tel" v-model="telefon" type="tel" class="input is-large" placeholder="Telefon" required="">
            </div>
      </div>
      <textarea id="uzenet" v-model="uzenet" type="textarea" placeholder="Üzenet*" class="input" style="height:11rem;font-weight: bold; resize: none"></textarea>

      <Popper arrow :content="this.bajok.join('\r\n')"
              close-delay="400"
              :show="mutasdVannakHibak">
            <button
                ref="gomb"
                class="button"
                @click="emailKuldes()"
                @mouseover="vannakEHibak"
                @mouseleave="tuntesdElVannakHibak"
            >Küldés</button>
          </Popper>
    </div>
    <div id="contactInfoModal" class="modal-card">
      <div class="modal-close" id="closeDesktop" @click="closeModal"></div>
        <h2 class="title">Elérhetőségeink</h2>
        <span class="contactWithIcon"><img src="location-dot-solid.svg"><b>Cím:&nbsp;</b>6065 Lakitelek, Tiszakécskei út 3</span>
        <span class="contactWithIcon"><img src="phone-solid.svg"><b>Telefon:&nbsp;</b><a href="tel:+36/76 448 544">+36/76 448 544</a></span>
        <span class="contactWithIcon"><img src="envelope-solid.svg"><b>Email:&nbsp;</b><a href="mailto:info@poliol.hu">info@poliol.hu</a></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}
.modal,.modal-background{
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.modal-background{
  position: absolute;
  background-color: rgba(10, 10, 10, 0.86);
}
.modal-content,
.modal-card {
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  background-color: white;
  border-radius: 1%;
  padding: 3%;
  .title {
    font-family: Inter-Medium,sans-serif;
    font-size: 2.5rem;
    color: black;
    margin-top: unset;
  }
}

.modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(10, 10, 10, 0.4);
  border: none;
  border-radius:100%;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  outline: none;
  position: absolute;
  right: 20px;
  top:20px;
  vertical-align: top;
}
.modal-close::before, .modal-close::after{
  background-color: white;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
  height: 2px;
  width: 50%;
}
.modal-close::after {
  height: 50%;
  width: 2px;
}
.modal-close:hover, .modal-close:focus{
  background-color: rgba(10, 10, 10, 0.5);
}

//URLAP

.urlap {
  $koz: 2rem;
  white-space: pre;
  background: whitesmoke;
  padding: 3%;
  height: 40vh;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  display: flex;
  width: 40vw;
  flex-direction: column;
  gap: $koz;
  box-shadow: 1px 10px 35px 20px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 1px 10px 35px 20px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 1px 10px 35px 20px rgba(0, 0, 0, 0.38);

  #urlap_elemek{
    display: grid;
    gap: $koz;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    .form-control{
      display: flex;
      input{
        width: 100%;
        font-size: 1.5rem;
      }
    }
  }
  #uzenet{
    font-size: 1.3rem;
  }


  .input {
    background-color: white;
    color: black;
    border: 2px solid white;
    border-radius: 5px;
    font-weight: bold;
    padding: 1%;
  }

  .button{
    padding: 1.2%;
    width: fit-content;
    font-size: 1.5rem;
    transition: 400ms ease;
    font-weight: 550;
    background-color: #FF8700;
    border-radius: 10px;
    color: white;
    opacity: .6;
    cursor: not-allowed;
    font-family: Inter-Medium,sans-serif;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    &:hover.mehet{
      background-color: lightgray;
      color: black;
    }
    &.mehet{
        opacity: 1;
        cursor: pointer;
    }
  }

  :deep(.popper) {
    background: #333333;
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
  }
  :deep(.popper #arrow::before) {
    background: #333333;
  }
}
#contactInfoModal{
  background-color: #FF8700;
  color: black;
  width: 19vw;
  height: 42vh;
  overflow: hidden;
}
.contactWithIcon{
  margin-bottom: 15%;
  height: 3rem;
  display: flex;
  font-size: .9vw;
  white-space: nowrap;
  align-items: center;
  img{
    height: 100%;
    margin-right: 5%
  }
  a{
    text-decoration: none !important;
  }
  &:first-of-type{
    margin-top: 6rem !important;
  }
}



@media only screen and (max-width: 600px) { //MOBIL NEZET
  .modal{
    #closeDesktop{
      display: none;
    }
    #closeMobile{
      display: block;
      background-color: brown;
      z-index: 150;
    }
    z-index: 100;
    display: flex;
    flex-direction: column;
    .urlap{
      height: auto;
      margin-top: 10%;
      border-bottom-left-radius: unset;
      border-top-right-radius: 10px;
      width: 80%;
      padding-bottom: 10%;
      .button{
        width: 35%;
      }
    }
    #contactInfoModal{
      width: 84%;
      height: fit-content;
      margin-bottom: 5%;

      .contactWithIcon{
        width: fit-content;
        text-align: center;
        margin-bottom: 1rem;
        font-size: 1rem;
        img{
          width: 4.5vw;
          margin-right: .5rem;
        }
        &:first-of-type{
          margin-top: 2rem !important;
        }
      }
    }
  }
}

</style>