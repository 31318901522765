<template>
  <div
      id="enquiry"
      class="grid gap0 w1300 middleMargin"
      :class="$store.state.screenSize === 'mobile' ? 'w100per singleColumn' : 'w1300 twoColumns'"
  >
    <div v-if="$store.state.screenSize !== 'mobile'" class="bgDark bg-image-circle">
      <div class="middleMargin w80per leftText p-top-230 p-bot-230">
        <text-with-line-after :text="gl('cup1')" />
        <h6 class="xSmallTitle capital InterMedium ls-03 m0">{{gl('cup2')}}</h6>
        <p class="largeText">{{gl('cup3')}}</p>
        <a
            :href="'tel:' + (this.contactPhone === undefined ? '+36 76 448 544' : this.contactPhone)"
            class="xLargeText no-decoration InterMedium fw-700 m0 m-top-20 orangeText"
        >{{ contactPhone === undefined ? '+36 76 448 544' : contactPhone }}</a>
      </div>
    </div>
    <div
        v-if="!thanks"
        class="grid gap20 leftText singleColumn bg-light-grey bg-image-industry p50"
        :class="$store.state.screenSize === 'mobile' ? '' : 'w500'"
    >
      <span>
        <text-with-line-after :text="gl('cup4')" />
        <h5 class="xSmallTitle capital m0 SpaceGrotesk ls-03 fw-700">{{gl('cup5')}}</h5>
      </span>
      <input v-model="name" required class="input smallText tr-1s m-top-20" type="text" :placeholder="gl('cup6')" />
      <!--<input v-model="company" class="input smallText tr-1s m-top-20" type="text" placeholder="Cégnév / Iparág" />-->
      <input v-model="email" required class="input smallText tr-1s m-top-20" type="email" placeholder="E-mail*" />
      <input
          v-model="telephone"
          required
          class="input smallText tr-1s m-top-20"
          type="tel"
          :placeholder="gl('cup7')"
          @keypress="restrictInput"
          @input="validateTelephone"
          @paste="preventNonNumericPaste"
      />
      <textarea v-model="message" required class="input textArea m-top-20 smallText tr-1s" type="text" :placeholder="gl('cup8')"></textarea>

      <div class="relative inline-block">
        <read-more-button
            @mouseenter="showPopover"
            @mouseleave="hidePopover"
            @click="sendEmail"
            :bordered="true"
            :disabled="missingInfo.length > 0"
            class="rightGridElement"
            :text="gl('cup9')"
            identification="eqp0"
            color="black"
            :orange="true"
        />
        <custom-popover ref="popover" v-show="popoverVisible">
          <template v-if="missingInfo.length">
            <ul>
              <li v-for="(info, index) in missingInfo" :key="index" class="p15">{{ info }}</li>
            </ul>
          </template>
        </custom-popover>
      </div>
    </div>
    <div
      v-else>
      <h3 class="largeTitle SpaceGrotesk ls-03">{{gl('ty')}}</h3>
    </div>
  </div>
</template>

<script>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";
import ReadMoreButton from "@/components/ReadMoreButton.vue";
import CustomPopover from "@/components/PopOver.vue";
import {email_dispatcher} from "@/mixins/email_dispatcher";

export default {
  name: "ContactUsPage",
  components: { ReadMoreButton, TextWithLineAfter, CustomPopover },
  props: ['contactPhone','contactEmail', 'contactTopic'],
  data() {
    return {
      name: '',
      company: '',
      email: '',
      message: '',
      telephone: '',
      popoverVisible: false,
      thanks:false
    };
  },
  computed: {
    missingInfo() {
      const missing = [];
      if (!this.name) missing.push(this.gl('cup10'));
      if (!this.email) missing.push(this.gl('cup11'));
      if (!this.telephone) missing.push(this.gl('cup12'));
      return missing;
    },
  },
  methods: {
    showPopover() {
      if (this.missingInfo.length > 0) {
        this.popoverVisible = true;
      }
    },
    hidePopover() {
      this.popoverVisible = false;
    },
    showThanks(){
      this.thanks = true;
    },
    sendEmail() {
      if (this.missingInfo.length === 0 && this.validatePhoneNumber(this.telephone)) {
        this.showThanks();
        this.contactEmail!==undefined&&email_dispatcher.methods.email_kuldes(
            "Új érdeklődés került rögzítésre a honlapunkon.<br><br><b>Érdeklődő adatai:</b><br>Név: " + this.name + "<br>Email cím: " + this.email + "<br>Telefon: " + this.telephone +"<br>Üzenet: " + this.message,
            this.contactEmail,
            "",
            'Új érdeklődés a honlapon -- '+ this.contactTopic
        );
        email_dispatcher.methods.email_kuldes(
            "Új érdeklődés került rögzítésre a honlapunkon.<br><br><b>Érdeklődő adatai:</b><br>Név: " + this.name + "<br>Email cím: " + this.email + "<br>Telefon: " + this.telephone +"<br>Üzenet: " + this.message,
            'lukacs.csaba@poliol.hu',
            "",
            'Új érdeklődés a honlapon -- '+ this.contactTopic
        );
      } else {
        this.showPopover();
      }
    },
    validatePhoneNumber(phone) {
      // Allow digits and the '+' character
      const phonePattern = /^[\d+]+$/;
      return phonePattern.test(phone);
    },
    restrictInput(event) {
      // Allow only numeric input and '+'
      if (!/[0-9+]/.test(event.key)) {
        event.preventDefault();
      }
    },
    validateTelephone(event) {
      // Remove non-numeric and non-'+' characters
      event.target.value = event.target.value.replace(/[^\d+]/g, '');
      this.telephone = event.target.value; // Update the model
    },
    preventNonNumericPaste(event) {
      // Prevent non-numeric and non-'+' paste
      const paste = event.clipboardData.getData('text');
      if (!/^[\d+]+$/.test(paste)) {
        event.preventDefault();
      }
    },
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
};
</script>

<style scoped lang="scss">
.relative {
  position: relative;
}
.inline-block {
  display: inline-block;
}
</style>
