<template>
  <div :class="$store.state.screenSize + ' footerComp'">
    <div class="footerInner">
      <h2 class="title">{{gl('fc1')}}</h2>
      <div class="fourGrid">

        <div class="column" >
          <h5 class="subtitle">email</h5>
          <h6>info@poliol.hu</h6>
          <h6 style="padding-top: 8px">sales@poliol.hu</h6>
        </div>
        <!-- ELERHETOSEGEK -->

        <div class="column">
          <h5 class="subtitle">{{gl('fc2')}}</h5>
          <h6>{{gl('fc3')}}</h6>
          <h6 style="padding-top: 8px"><a href="tel:+36203422988">+36 20 342 2988</a></h6>
        </div>
        <div class="column">
          <h5 class="subtitle">{{gl('fc4')}}</h5>
          <h6>{{gl('fc5')}}</h6>
          <h6 style="padding-top: 8px"><a href="tel:+36204850585">+36 20 485 0585</a></h6>
        </div>
        <div class="column">
          <h5 class="subtitle">{{gl('fc6')}}</h5>
          <h6>{{gl('fc7')}}</h6>
          <h6 style="padding-top: 8px"><a href="tel:+36300759259">+36 30 075 9259</a></h6>
        </div>
        <div class="column">
          <h5 class="subtitle">{{gl('fc8')}}</h5>
          <h6 style="padding-top: 8px"><a href="tel:+3676448544">+36 76 448 544</a></h6>
        </div>
        <div class="column">
          <h5 class="subtitle" style="margin-bottom: 5px">{{gl('fc10')}}</h5>
          <h6 style="line-height: 1.6rem;">Poliol Gépgyártó és Csomagolástechnikai Kft.<br>
            H-6065 Lakitelek, Tiszakécskei út 3.</h6>
        </div>
      </div>
      <social-media class="middleMargin"/>
      <p class="middleMargin wMin no-wrap m-top-20">{{gl('fc9')}}</p>
    </div>

  </div>
</template>
<script>

import {defineComponent} from "vue";
import SocialMedia from "@/components/SocialMedia.vue";

export default defineComponent({
  components: {SocialMedia},
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
})
</script>
<style scoped lang="scss">
.footerComp{
  background-color: rgb(21,20,28);
  width: 100%;
  .footerInner {
    color: rgb(193, 193, 193);
    width: 1300px;
    margin: 0 auto;
    padding-top: 20px;
    text-align: left;
    .title {
      font-size: var(--titleFont);
      font-family: Space-Grotesk, sans-serif;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      letter-spacing: -1.2px;
    }
    .fourGrid {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 4rem;
      width: 100%;
      padding-bottom: 30px;
      .column:last-of-type{
        grid-column: span 3
      }
      .thick{
        grid-column: span 2;
      }
      .subtitle {
        color: var(--orange-color);
        text-transform: uppercase;
        font-size: var(--smallTextFont);
        font-weight: 700;
      }

      ul {
        padding: unset;
        font-size: var(--textFont);
        li {
          list-style: none;
          margin-bottom: 5px;
          line-height: 1.8rem;
        }
      }
      h6{
        font-size: 19px;
        margin: unset;
        white-space: nowrap;
        a{
          text-decoration: none;
          color: rgb(193, 193, 193);
        }
      }
    }
  }

  &.mobile,
  &.tablet{
    .footerInner{
      width: 85%;
      padding-top: 75px;
      .fourGrid{
        grid-template-columns: 100%;

        gap: 40px;

        .thick{
          grid-column: unset;
        }
        .column{
          grid-column: unset !important;
          h6{
            white-space: pre-wrap;
          }
        }
      }
    }
  }
  &.tablet{
    .footerInner{
      width: 90%;
      .fourGrid{
        grid-template-columns: 50% 50%;
      }
    }
  }
}
</style>