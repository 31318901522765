<template>
<div :class=" $store.state.screenSize + ' photoMontage'">
  <img v-for="photo in photos" v-bind:key="photo.url" :src="photo.src">
</div>
</template>
<script>
export default {
  name: "PhotoMontage",
  props: ['photos'],
  data(){
    return{

    }
  }
}
</script>
<style scoped lang="scss">
.photoMontage{
  height: 92vh;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 25% 25% 50%;
  grid-template-rows:50% 50%;
  gap: 20px;
  overflow: hidden;
  img{
    max-width: 100%;
    max-height: 100%;
    &:nth-of-type(1){
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &:nth-of-type(2){
      grid-column: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }
  &.mobile,
  &.tablet{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: unset;
    img{
      max-width: 100%;
      max-height: 100%;
      &:nth-of-type(1){
        grid-column:1
      }
      &:nth-of-type(2){
        grid-column: 1;
        grid-row: 2;
      }
    }
  }
}
</style>