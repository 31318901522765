import axios from "@/plugins/axios";

export const email_dispatcher = {
    methods:{
        lekereses_email_kuldes(username, uzenet, targy){
            let vm = this;
                // email cim es nev lekerese
                axios.get('api/users.php', {
                    params: {
                        type: "user",
                        un: username,
                    }
                }).then(function (response) {
                    // megvan az email cim es a nev, kuldhetjuk az emailt
                    vm.email_kuldes(uzenet, response.data[0].email, response.data[0].name, targy)
                })
        },

        email_kuldes(uzenet, kinek, nev, targy){
            axios.get('/API/email.php', {
                params: {
                    nev: nev,
                    to: kinek,
                    targy: targy,
                    uzenet: uzenet,
                    /*uzenet_2: 
                        '<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">\n' +
                        '<head>\n' +
                        '<title></title>\n' +
                        '<meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>\n' +
                        '<meta content="width=device-width, initial-scale=1.0" name="viewport"/>\n' +
                        '<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->\n' +
                        '<style>\n' +
                        '\t\t* {\n' +
                        '\t\t\tbox-sizing: border-box;\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\tbody {\n' +
                        '\t\t\tmargin: 0;\n' +
                        '\t\t\tpadding: 0;\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\ta[x-apple-data-detectors] {\n' +
                        '\t\t\tcolor: inherit !important;\n' +
                        '\t\t\ttext-decoration: inherit !important;\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\t#MessageViewBody a {\n' +
                        '\t\t\tcolor: inherit;\n' +
                        '\t\t\ttext-decoration: none;\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\tp {\n' +
                        '\t\t\tline-height: inherit\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\t.desktop_hide,\n' +
                        '\t\t.desktop_hide table {\n' +
                        '\t\t\tmso-hide: all;\n' +
                        '\t\t\tdisplay: none;\n' +
                        '\t\t\tmax-height: 0px;\n' +
                        '\t\t\toverflow: hidden;\n' +
                        '\t\t}\n' +
                        '\n' +
                        '\t\t@media (max-width:620px) {\n' +
                        '\t\t\t.desktop_hide table.icons-inner {\n' +
                        '\t\t\t\tdisplay: inline-block !important;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.icons-inner {\n' +
                        '\t\t\t\ttext-align: center;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.icons-inner td {\n' +
                        '\t\t\t\tmargin: 0 auto;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.fullMobileWidth,\n' +
                        '\t\t\t.image_block img.big,\n' +
                        '\t\t\t.row-content {\n' +
                        '\t\t\t\twidth: 100% !important;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.mobile_hide {\n' +
                        '\t\t\t\tdisplay: none;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.stack .column {\n' +
                        '\t\t\t\twidth: 100%;\n' +
                        '\t\t\t\tdisplay: block;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.mobile_hide {\n' +
                        '\t\t\t\tmin-height: 0;\n' +
                        '\t\t\t\tmax-height: 0;\n' +
                        '\t\t\t\tmax-width: 0;\n' +
                        '\t\t\t\toverflow: hidden;\n' +
                        '\t\t\t\tfont-size: 0px;\n' +
                        '\t\t\t}\n' +
                        '\n' +
                        '\t\t\t.desktop_hide,\n' +
                        '\t\t\t.desktop_hide table {\n' +
                        '\t\t\t\tdisplay: table !important;\n' +
                        '\t\t\t\tmax-height: none !important;\n' +
                        '\t\t\t}\n' +
                        '\t\t}\n' +
                        '\t</style>\n' +
                        '</head>\n' +
                        '<body style="background-color: #FFFFFF; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;" width="100%">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #132437;" width="100%">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-repeat: no-repeat; background-position: center top; color: #000000; background-image: url(\'images/blue-glow_3.jpg\'); width: 600px;" width="600">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">\n' +
                        '<div align="center" class="alignment" style="line-height:10px"><img class="fullMobileWidth" src="images/top-rounded.png" style="display: block; height: auto; border: 0; width: 600px; max-width: 100%;" width="600"/></div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #132437;" width="100%">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; background-position: center top; color: #000000; width: 600px;" width="600">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 10px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:5px;padding-left:20px;padding-right:20px;padding-top:5px;width:100%;">\n' +
                        '<div align="center" class="alignment" style="line-height:10px"><img alt="book shelf" class="big" src="images/PTR_FO_LOGO_1.png" style="display: block; height: auto; border: 0; width: 560px; max-width: 100%;" title="book shelf" width="560"/></div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ff7d14; background-image: url(\'images/orange-gradient-wide.png\'); background-repeat: no-repeat;" width="100%">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 600px;" width="600">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="heading_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:5px;padding-top:25px;text-align:center;width:100%;">\n' +
                        '<h1 style="margin: 0; color: #555555; direction: ltr; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; font-size: 36px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0;"><strong>'+targy+'</strong></h1>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="text_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:20px;padding-left:30px;padding-right:30px;padding-top:20px;">\n' +
                        '<div style="font-family: sans-serif">\n' +
                        '<div class="" style="font-size: 14px; mso-line-height-alt: 25.2px; color: #737487; line-height: 1.8; font-family: Arial, Helvetica Neue, Helvetica, sans-serif;">\n' +
                        '<p style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 32.4px;"><span style="font-size:18px;">'+uzenet+'</span></p>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="button_block block-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:30px;padding-left:15px;padding-right:15px;padding-top:20px;text-align:center;">\n' +
                        '<div align="center" class="alignment">\n' +
                        '<!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="http://feladatkezelo.poliol.priv/" style="height:52px;width:288px;v-text-anchor:middle;" arcsize="8%" stroke="false" fillcolor="#ff7d14"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><![endif]--><a href="https://www.example.com" style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#ff7d14;border-radius:4px;width:auto;border-top:0px solid transparent;font-weight:400;border-right:0px solid transparent;border-bottom:0px solid transparent;border-left:0px solid transparent;padding-top:10px;padding-bottom:10px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-size:16px;text-align:center;mso-border-alt:none;word-break:keep-all;" target="_blank"><span style="padding-left:60px;padding-right:60px;font-size:16px;display:inline-block;letter-spacing:normal;"><span dir="ltr" style="word-break: break-word; line-height: 32px;">Alkalmazás megnyitása</span></span></a>\n' +
                        '<!--[if mso]></center></v:textbox></v:roundrect><![endif]-->\n' +
                        '</div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="image_block block-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:40px;width:100%;padding-right:0px;padding-left:0px;">\n' +
                        '<div align="center" class="alignment" style="line-height:10px"><img alt="line" class="big" src="images/divider.png" style="display: block; height: auto; border: 0; width: 541px; max-width: 100%;" title="line" width="541"/></div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ff7d14;" width="100%">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-position: center top; color: #000000; width: 600px;" width="600">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">\n' +
                        '<div align="center" class="alignment" style="line-height:10px"><img class="fullMobileWidth" src="images/bottom-rounded.png" style="display: block; height: auto; border: 0; width: 600px; max-width: 100%;" width="600"/></div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="text_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:5px;padding-left:5px;padding-right:5px;padding-top:30px;">\n' +
                        '<div style="font-family: sans-serif">\n' +
                        '<div class="" style="font-size: 12px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14.399999999999999px; color: #262b30; line-height: 1.2;">\n' +
                        '<p style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 16.8px;"><strong><span style="font-size:12px;">Poliol Termeléstámogató Rendszer | © 2022-2023 POLIOL KFT.</span></strong></p>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="text_block block-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="padding-bottom:35px;padding-left:10px;padding-right:10px;padding-top:5px;">\n' +
                        '<div style="font-family: sans-serif">\n' +
                        '<div class="" style="font-size: 12px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14.399999999999999px; color: #262b30; line-height: 1.2;">\n' +
                        '<p style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 16.8px;">Ez egy automatikusan küldött email, ne válaszolj rá!</p>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td>\n' +
                        '<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 600px;" width="600">\n' +
                        '<tbody>\n' +
                        '<tr>\n' +
                        '<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">\n' +
                        '<table border="0" cellpadding="0" cellspacing="0" class="icons_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="pad" style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">\n' +
                        '<table cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">\n' +
                        '<tr>\n' +
                        '<td class="alignment" style="vertical-align: middle; text-align: center;">\n' +
                        '<!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->\n' +
                        '<!--[if !vml]><!-->\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table>\n' +
                        '</td>\n' +
                        '</tr>\n' +
                        '</tbody>\n' +
                        '</table><!-- End -->\n' +
                        '</body>\n' +
                        '</html>'*/
                }
            })
        }
    }
}