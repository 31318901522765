<template>
  <div
      :id="identification"
      :style="computedStyle"
      class="readMoreButton"
      :class="buttonClasses"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @click="goto!==undefined&&$store.dispatch('navigateTo', goto);"
  >
    <div class="left">{{ text }}</div>
    <div class="right">
      <svg :class="[orange&&'black-fill']">
        <path :id="identification + '_path0'" d="m11.75 13.1-1.275-1.275L14.1 8.2l.85-.575-.1-.3-1.025.125H0V5.625h13.825l1.025.15.1-.3-.85-.575-3.625-3.625L11.75 0l5.9 5.9v1.3Z"></path>
        <path :id="identification + '_path1'" d="m11.75 13.1-1.275-1.275L14.1 8.2l.85-.575-.1-.3-1.025.125H0V5.625h13.825l1.025.15.1-.3-.85-.575-3.625-3.625L11.75 0l5.9 5.9v1.3Z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReadMoreButton",
  props: {
    text: String,
    identification: String,
    bordered: Boolean,
    color: String,
    disabled: Boolean,
    goto: String,
    orange:Boolean
  },
  computed: {
    computedStyle() {
      return {
        '--color': this.color,
        '--bgColor':this.orange&&'rgb(255, 118, 24)'
      };
    },
    buttonClasses() {
      return {
        bordered: this.bordered,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    handleMouseEnter() {
      if (!this.disabled) {
        this.arrowAnimationEnter();
      }
    },
    handleMouseLeave() {
      if (!this.disabled) {
        this.arrowAnimationLeave();
      }
    },
    arrowAnimationEnter() {
      document.getElementById(this.identification + '_path0').classList.add('selected');
      document.getElementById(this.identification + '_path1').classList.add('selected');
    },
    arrowAnimationLeave() {
      document.getElementById(this.identification + '_path0').classList.remove('selected');
      document.getElementById(this.identification + '_path1').classList.remove('selected');
      document.getElementById(this.identification + '_path0').classList.add('unselected');
      document.getElementById(this.identification + '_path1').classList.add('unselected');
      setTimeout(() => {
        document.getElementById(this.identification + '_path0').classList.remove('unselected');
        document.getElementById(this.identification + '_path1').classList.remove('unselected');
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.readMoreButton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  --main-color: white; // Default color

  .left,
  .right {
    font-family: Inter-Medium, sans-serif;
    color: var(--color, var(--main-color));
    z-index: 10;
    background: var(--bgColor, transparent);
  }

  &.bordered .left,
  &.bordered .right {
    border: .5px solid var(--color, var(--main-color));
    padding: 16px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .left {
    font-family: Inter-Medium, sans-serif;
    font-weight: bold;

  }

  .right {
    padding-left: 2%;

    // Arrow styling
    svg {
      box-sizing: border-box;
      font-size: 17px;
      width: 17.65px;
      height: 13.1px;
      fill: var(--orange-color);
      transition: all 2s ease-out;
      overflow: hidden;

      // Hover animation
      path {
        &.selected {
          animation: firstArrowEnter 1s forwards;
        }
        &.unselected {
          transform: translate(200%);
          animation: firstArrowLeave 1s;
        }
        &:nth-of-type(2) {
          transform: translate(-200%);
          &.selected {
            animation: secondArrowEnter 1s forwards;
          }
          &.unselected {
            transform: translate(0);
            animation: secondArrowLeave 1s;
          }
        }
      }
    }

    @keyframes firstArrowEnter {
      80%, 100% {
        transform: translate(200%);
      }
    }

    @keyframes secondArrowEnter {
      80%, 100% {
        transform: translate(0%);
      }
    }

    @keyframes firstArrowLeave {
      80%, 100% {
        transform: translate(0%);
      }
    }

    @keyframes secondArrowLeave {
      80%, 100% {
        transform: translate(-200%);
      }
    }
  }
}
</style>
