<template>
  <h4 v-if="linePosition === undefined || linePosition === 'beside'" class="textWithLineAfter beside" :style="computedStyle">{{text}}</h4>
  <h4 v-else-if="linePosition === 'below'" class="textWithLineAfter below" :style="computedStyle">{{text}}</h4>
</template>

<script>
export default {
  name: "TextWithLineAfter",
  props:['text','color','linePosition'],
  computed: {
    computedStyle(){
      return{
        '--color':this.color
      }
    }
  }
}
</script>

<style scoped lang="scss">
.textWithLineAfter{
  position: relative;
  text-transform: uppercase;
  width: fit-content;
  font-size: 14px;
  height: 2vh;
  font-family: 'Inter', sans-serif;
  color: var(--color,var(--orange-color));

  &.beside{
    &::after{
      content: "";
      position: absolute;
      right: -60px;
      height: 1.5px;
      top: 35%;
      width: 50px;
      background-color: var(--color,var(--orange-color));
    }
  }

  &.below{
    text-align: left;
    width: 100%;
    padding-bottom: .7%;
    border-bottom: .6px solid var(--color,var(--orange-color));
  }
}
</style>