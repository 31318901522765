<template>
  <div class="markerContainer" :style="{left:this.markerData.xCoordinate+'px',top:this.markerData.yCoordinate+'px'}" >
    <div class="markerHitBox" @mouseover="showPopup" @mouseleave="hidePopup">
      <div class="marker" :style="computedStyle" />
    </div>
    <div v-if="popupShow" class="line" :id="markerData.code+'_line'" :style="computedStyle"/>
    <div v-if="popupShow" class="popup" :id="markerData.code+'_popup'" :style="computedStyle">
      <b>{{markerData.country}}</b>
    </div>
  </div>
</template>
<script>
  export default{
    name:'MapMarker',
    props:['markerData','color','popupBackground'],
    data(){
      return{
        popupShow:false
      }
    },
    methods:{
      showPopup(){
        this.popupShow = true
        //document.getElementById(this.markerData.code+'_popup').classList.add('withBackground');
      },
      hidePopup(){
        if(this.popupShow){
          document.getElementById(this.markerData.code+'_line').classList.add('leaveAnimation');
          document.getElementById(this.markerData.code+'_popup').classList.add('leaveAnimation');
          setTimeout(()=>{this.popupShow=false},801)
        }
      }
    },
    computed:{
      computedStyle(){
        return{
          '--custom-color':this.color,
          '--custom-background': this.popupBackground
        }
      }
    },
  }

</script>

<style scoped lang="scss">
  .markerContainer{
    position: absolute;
    display: flex;
  }
  .markerHitBox{
    z-index: 200;
    display: flex;
    height: 50px;
    width: 50px;
    align-items: center;
  }
  .marker{
    margin: 0 auto;
    width: 23%;
    height: 23%;
    background-color: var(--custom-color,white);
    border-radius: 15px;
    animation: pulse-orange 2s infinite;
  }
  .line{
    width: 100px;
    opacity: 0;
    height: 1.2px;
    background-color: var(--custom-color,white);
    margin-left: -15px;
    margin-right: 3px;
    margin-top: 24px;
    animation: float-from-right 1.5s .4s ease forwards;
  }
  .popup{
    opacity: 0;
    text-align: left;
    margin-top: 14px;
    color: var(--custom-color,white);
    text-transform: uppercase;
    font-size: 18px;
    background-color: var(--custom-background, transparent);
    height: min-content;
    width: max-content;
    animation: appearSmoothly 1s ease forwards;
  }
  .leaveAnimation{
    animation: leave .8s;
  }


  @keyframes pulse-orange {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 var(--orange-color);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }

  @keyframes float-from-right {
   from {
     opacity: 0;
      transform: translateX(30%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes leave{
    from{
      opacity:1;
    }
    to{
      opacity: 0;
    }
  }


</style>