<script>
import {AwesomeSocialButton} from "awesome-social-button";

export default {
  name: "SocialMedia",
  components: {AwesomeSocialButton},
}
</script>

<template>
<div id="socialMediaContainer">
  <a href="https://www.facebook.com/profile.php?id=100054475269695"><awesome-social-button
      type="facebook"
      :link="{ src: 'https://facebook.com'}"
      shape="square"
      tooltip="Facebook"
      :width="60"
  /></a>
  <a href="https://www.instagram.com/poliol_ig/"><awesome-social-button
      type="instagram"
      shape="square"
      :link="{ src: 'https://facebook.com'}"
      tooltip="Instagram"
      :width="60"
  /></a>
  <a href="https://www.linkedin.com/company/poliol-kft/"><awesome-social-button
      type="linkedin"
      shape="square"
      :link="{ src: 'https://facebook.com'}"
      tooltip="LinkedIn"
      :width="60"
  /></a>
  <a href="https://www.youtube.com/@poliolkft.4639"><awesome-social-button
      type="youtube"
      :link="{ src: 'https://facebook.com'}"
      shape="square"
      tooltip="YouTube"
      :width="60"

  /></a>
</div>
</template>

<style scoped lang="scss">
  #socialMediaContainer{
    width: min-content;
    display: grid;
    //gap: 1.5rem;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: auto;
    .awesome-social-button{
      background: transparent;
      box-shadow: unset;
      color: var(--orange-color);
    }
  }
</style>