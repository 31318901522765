<template>
<div class="navBarItem">
  <img src="POLIOL_I_50x50.webp">
  <span class="smallText">{{text}}</span>
</div>
</template>
<script>
export default {
  name: "NavBarItem",
  props:['text','link'],
}
</script>
<style scoped lang="scss">
.navBarItem{
  position: relative;
  font-family: Inter-Medium,sans-serif;
  font-size: 1.3rem;
  margin-right: 1.5%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  img{
    display: none;
    height: 1.3rem;
    margin-right: 6px;
  }
  &:hover{
    cursor: pointer;
  }
  &::after{
    content:'';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 100%;
    background-color: #FF8700;
    display: none;
  }
  &:hover::after{
    display: block;
  }
  &.chosen{
    font-weight: bold;
    img{
      display: block;
    }

    /*&::before{
      content:'';
      position: absolute;
      height: 30px;
      width: 6px;
      transform: rotate(-20deg);
      background-color: #FF8700;
      left: -.7vw;
      border-radius: 10px;
    }*/
  }
}
</style>