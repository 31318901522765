<script setup>
//import { useElementVisibility } from '@vueuse/core'
import BranchCard from "@/components/BranchCard.vue";
import AboutUs from "@/components/AboutUs.vue";
import WireFrameDesign from "@/components/WireFrameDesign.vue";
import PhotoMontage from "@/components/PhotoMontage.vue";
import BlowingMachineWireFrameDesign from "@/components/BlowingMachineWireFrameDesign.vue";
import MapLocations from "@/components/MapLocations.vue";
import PhotoMontagePortrait from "@/components/PhotoMontagePortrait.vue";
import BottleProductionMain from "@/components/BottleProductionMain.vue";
import CustomCarousel from "@/components/CustomCarousel.vue";
import FooterComp from "@/components/FooterComp.vue";
import ContactUsPage from "@/components/ContactUsPage.vue";
import AdVertisement from "@/components/Advertisement.vue";
</script>
<template>
  <div class="home">
    <div :class="$store.state.screenSize + ' i-betu-hatter'">
      <div id="mainPageBanner" :class="$store.state.screenSize">
        <div :class="$store.state.screenSize + ' leftSide'">
          <img src="i_betu_spottal.png" v-if="$store.state.screenSize === 'mobile' || $store.state.screenSize === 'tablet'">
            <h1 :class="$store.state.screenSize + ' title'">
              {{gl('h1')}}<br> <span class="orange">{{gl('h2')}}</span>{{gl('h3')}}
            </h1>
            <h2 class="subtitle">
              {{gl('h4')}}
            </h2>
        </div>
        <div :class="$store.state.screenSize + ' rightSide'" v-if="['desktop','laptop','tabletL'].includes($store.state.screenSize)">
          <div id="counters">
            <counter class="count" :color="$store.state.screenSize === 'mobile'? 'black':''" :text="gl('h5')" maxValue="120"></counter>
            <counter class="count" :color="$store.state.screenSize === 'mobile'? 'black':''" :text="gl('h6')" maxValue="30"></counter>
            <counter class="count" :color="$store.state.screenSize === 'mobile'? 'black':''" :text="gl('h7')" maxValue="110"></counter>
            <counter class="count" :color="$store.state.screenSize === 'mobile'? 'black':''" :text="gl('h8')" maxValue="85"></counter>
          </div>
        </div>
      </div>
    </div>

    <div id="tiles">
      <branch-card v-for="branch in branches" v-bind:key="branch.title" :title="branch.title" :url="branch.url" :image="branch.image" :fact_title="branch.fact_title" :fact_text="branch.fact_text" :title_color="branch.title_color" :subtitle_color="branch.subtitle_color" @click="$store.dispatch('navigateTo',branch.url)" :after_text="$store.state.locale.current.hb7"></branch-card>
    </div>
    <enquiry-button class="m-bot-40" @click="scrollToComponent('enquiry')"/>

    <about-us></about-us>
    <div class="animatedText"><p>{{gl('at')}}</p></div>
    <!--<MapLocations/>-->
    <h3 class="smallTitle capital InterExtraBold blackText p-top-40 m0 mBot10">{{gl('h10')}}</h3>
    <wire-frame-design/>
    <photo-montage :photos="[{src:'excavator_pic.jpg'},{src: 'hegesztes.webp'}, {src:'banyaKanal.webp'}, {src: 'robothegesztes.webp'}, ]"/>
    <CustomCarousel :slides="['knorr-bremse-logo-black-and-white.png','zf_logo.png','grundfos-logo-black-and-white.png','bpw_logo.png','schaeffler_logo.jpeg','tt.jpg','ssab-logotype-black-rgb.png']"/>

    <h3 class="smallTitle capital InterExtraBold orangeText p-top-40 m0 mBot10">{{gl('h11')}}</h3>
    <blowing-machine-wire-frame-design/>
    <div class="animatedText"/>
    <MapLocations/>
    <photo-montage-portrait/>
    <AdVertisement
        image="PE_Gep.png"
        :slogan="gl('h12')"
        logo="PE-UNI_keszletkisopres.png"
        :text0="gl('h13')"
        :list="[
            gl('h14'),
            gl('h15'),
            gl('h16')
        ]"
        :text1="gl('h17')"
        @clicked="scrollToComponent('enquiry')"
    />
    <h3 class="smallTitle capital InterExtraBold blackText p-top-40 m0 mBot10">{{gl('h18')}}</h3>

    <BottleProductionMain/>
    <div class="animatedText"><p>{{gl('at')}}</p></div>
    <photo-montage :photos="[{src:'feher_flakonok.jpeg'}, {src:'szerszamgyartas.jpg'}, {src: 'flakon_tervezes.jpg'},{src: 'plastic_bottle_city.png'} ]"/>
    <ContactUsPage title="Keressen minket!"
                   subtitle="Forduljon hozzánk bizalommal"
                   contact-phone="06 20 485 0585"
                   contact-email="lukacs.csaba@poliol.hu"
                   contact-topic="általános"
                   class="m-top-80" />
    <CustomCarousel :bordered="false" :slides="['medikemia-logo.png','szentkiralyi_logo.jpg','fantana-mare_logo.png','resilux_logo.jpeg','royalsekt_logo.jpeg','florin_logo.png','caola_logo.png']" style="margin-top: 15px"/>
    <FooterComp/>
  </div>
</template>

<script>
// @ is an alias to /src

import Counter from "@/components/counter.vue";
//import FooterComp from "@/components/footer.vue";
import EnquiryButton from "@/components/EnquiryButton.vue";

export default {
  name: 'HomeView',
  components: {
    Counter,
    EnquiryButton
  },
  props:['lang'],
  data(){
    return{
      deviceScreen:null,
      enquiryformVisible: false,
      style:{
        borderRadius: '8px',
      }
    }
  },
  computed: {
    branches() {
      return [
        {
          title: this.$store.state.locale.current.hb1,
          image: 'blowingMachine',
          url: this.$store.state.sites[3].url,
          fact_title: this.$store.state.locale.current.hb2
        },
        {
          title: this.$store.state.locale.current.hb3,
          image: 'flakongyartas',
          url: this.$store.state.sites[1].url,
          fact_title: this.$store.state.locale.current.hb4,
          subtitle_color: 'white'
        },
        {
          title: this.$store.state.locale.current.hb5,
          image: 'szerelekgyartas',
          url: this.$store.state.sites[2].url,
          fact_title: this.$store.state.locale.current.hb6,
          title_color: 'white'
        },
      ]
    },
  },

  created() {
    this.$store.commit('setChosenTab',this.$store.state.sites[0].url)
    this.$route.path.split('/')[1]!==this.$store.state.chosenLang&&this.$store.commit('setLanguage',this.$route.path.split('/')[1])
    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector('head title');

    descEl.setAttribute('content', "Poliol Kft. Főoldal");
    titleEl.textContent = "Poliol Kft. Főoldal";
  },
  methods:{
    scrollToComponent(componentId) {
      var element = document.getElementById(componentId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling
          block: 'center' // Scroll to the middle of the element
        });

        // Add a class to highlight the element
        element.classList.add('border-color-orange');

        // Remove the class after 3 seconds
        setTimeout(function() {
          element.classList.remove('border-color-orange');
        }, 3000);
      } else {
        console.warn('Element with ID ' + componentId + ' not found.');
      }
    },
    gl(what){
      return this.$store.state.locale.current[what]
    }
  },

}
</script>
<style lang="scss">
  .home {
    width: 100%;
    .i-betu-hatter {
      margin-top: 2dvh;
      width: 100%;
      height: 45%;
      display: flex;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      background-image: url("/public/i_betu_spottal.png");

      #mainPageBanner {
        font-family: 'Inter', Arial, sans-serif;
        display: flex;
        width: 100%;
        //margin: .1vw;
        padding-left: 20%;
        padding-top: 1%;
        padding-bottom: 1%;

        .title {
          font-family: 'Inter-ExtraBold', sans-serif !important;
          font-size: var(--largeTitleFont, 63px);
          .orange {
            color: var(--orange-color);
          }
        }

        .title, .subtitle {
          text-align: left;
          padding: unset;
          margin: unset;
        }

        .subtitle {
          font-size: 2.3vh;
          padding-top: 3.5vh;
        }

        .leftSide {
          width: 45%;
        }

        .rightSide {
          display: flex;
          flex-direction: row;

          width: 70%;

          #counters {
            width: 100%;
            display: grid;
            grid-template-columns: auto auto;
            height: 100%;
            align-items: center;

            .counter {
              font-size: 4.8vh !important;
            }

            .count {
              h3 {
                color: var(--dark-font-color);
              }
            }
          }
        }
        &.mobile,
        &.tablet{
          display: grid;
          padding:unset;
          grid-template-columns: 1fr;
          width: 100%;
          margin: 0 auto;
          overflow: visible;
          position: relative;
          .leftSide,.rightSide{
            margin: 0 auto;
            padding: unset;
          }
          .leftSide{
            width: 80%;
            margin-top: 30px;
            height: min-content;
            img{
              position: absolute;
              z-index: 0;
              left: 5%;
              top: 0%;
            }
            .title{
              position: relative;
              z-index: 3;
              .orange{
                font-size: 35px;
              }
            }
            .subtitle{
              display: none;
            }
          }
          .rightSide{
            margin-top: 100px;
            #counters{
              grid-template-columns: 1fr;
              gap: 55px;
              .count{
                .counter{
                  font-size: 35px !important;
                }
                h3{
                  font-size: 25px;
                  color: black;
                }
              }
            }
          }
        }
        &.tablet{
          .leftSide{
            img{
              top:80px;
            }
            .title{
              font-size: 60px;
            }
            margin-top: 200px;
          }
        }
      }
      &.mobile,
      &.tablet{
        background-image: unset;
        height: unset;
      }
    }

    #services {
      font-size: 3vh;
      margin-bottom: unset;
      font-family: 'Inter-ExtraBold', sans-serif;
      text-transform: uppercase;
    }

    #tiles {
      display: grid;
      height: fit-content;
      grid-template-columns: repeat(3, 1fr); /* 6 columns with equal width */
      gap: 1.5rem;
      padding: 1.5vw;
      padding-bottom: unset;
      margin-top: 1vh;
      margin-bottom: 90px;
    }

    .animatedText {
      //margin-top: ;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      font-size: 40px;
      font-family: Space-Grotesk, sans-serif;
      font-weight: 500;
      color: rgb(255, 118, 24);

      p {
        margin-bottom: .5%;
        -webkit-font-smoothing: antialiased;
        letter-spacing: -2px;
      }
    }

    #footer {
      height: 7vh;
    }

    &.mobile,
    &.tablet{
      #tiles{
        margin-top: 80px;
        grid-template-columns: 1fr;
        .branchCard{
          .fact{
            h1{
              font-size: 30px;
            }
            h2{
              display: none;
            }
          }
        }
      }
    }


  }

  //ANIMACIOK
  :root {
    --newNum: 4;
    --duration1: 2s;
    --duration2: calc(var(--duration1) / 2);
    --delay1: calc(var(--duration2) / 2);
    --delay2: calc(var(--duration2) + var(--delay1));
    --easing: cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .count {
    font-family: Inter-ExtraBold,sans-serif;
    opacity: 0;
    color: var(--orange-color);
    animation: appearSmoothly .5s var(--easing) forwards;
  }
  .animatedText{
    p{
      animation: move 120s linear infinite;
    }
  }
  .mobile .animatedText{
    p{
      animation: move 23s linear infinite;
    }
  }


  @keyframes appearSmoothly {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  @keyframes pulse-orange {
    0% {
      //transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
      //transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
      //transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }

  @keyframes move {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }


</style>